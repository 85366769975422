<script setup lang="ts">
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import { Link } from "@inertiajs/vue3";
import { useForm } from "laravel-precognition-vue-inertia";
import Input from "@/Components/Input.vue";
import Button from "@/Components/Button.vue";
import Checkbox from "@/Components/Checkbox.vue";
import AuthLayout from "@/Layouts/AuthLayout.vue";
import BaseLayout from "@/Layouts/BaseLayout.vue";

defineOptions({
  layout: (h, page) =>
    h(AuthLayout, { title: "Logga in" }, () => h(BaseLayout, {}, () => page)),
});

const form = useForm("post", route("login"), {
  email: "",
  password: "",
  remember: false,
});

const submit = () =>
  form.submit({
    onFinish: () => {
      form.reset("password");
    },
  });
</script>

<template>
  <form @submit.prevent="submit" class="space-y-6">
    <div>
      <InputLabel
        for="email"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Email</InputLabel
      >
      <div class="mt-2">
        <Input
          id="email"
          name="email"
          type="email"
          placeholder="namn@företag.se"
          autocomplete="email"
          v-model="form.email"
          :has-error="form.invalid('email')"
          tabindex="1"
          aria-describedby="email-error"
          @change="form.validate('email')"
        />
      </div>
      <InputError for-id="email" class="mt-1" :error="form.errors.email" />
    </div>

    <div>
      <InputLabel for="password">Lösenord</InputLabel>
      <div class="mt-2">
        <Input
          id="password"
          name="password"
          type="password"
          v-model="form.password"
          autocomplete="current-password"
          :has-error="form.invalid('password')"
          tabindex="2"
          @change="form.validate('password')"
          aria-describedby="password-error"
        />
      </div>
      <InputError
        for-id="password"
        class="mt-1"
        :error="form.errors.password"
      />
    </div>

    <div>
      <div class="flex items-center justify-between">
        <label class="flex items-center">
          <Checkbox
            name="remember"
            v-model:checked="form.remember"
            tabindex="3"
          />
          <span class="ms-2 text-sm text-gray-600">Kom ihåg mig</span>
        </label>
        <div class="text-sm">
          <Link :href="route('password.request')" class="link" tabindex="5">
            Glömt ditt lösenord?
          </Link>
        </div>
      </div>
    </div>
    <div>
      <Button
        class="w-full justify-center"
        type="submit"
        :disabled="form.processing"
        tabindex="4"
        >Logga in</Button
      >
    </div>
  </form>

  <p class="mt-10 text-center text-sm text-gray-500">
    Inget konto?
    {{ " " }}
    <Link :href="route('register')" class="link" tabindex="5"
      >Starta en gratis provperiod
    </Link>
  </p>
</template>
